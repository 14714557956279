/* eslint-env jest */
import mutations from './mutations';

jest.mock('@/models/Viewcell', () => {
  const v = function v(o) {
    Object.entries(o).forEach(([key, value]) => {
      this[key] = value;
    });
  };
  return v;
});

describe('mutations', () => {
  it.skip('setScene should set scene data to state', () => {
    const state = {
      scenes: [],
    };

    mutations.setScene(state, {
      scene_list: {
        0: {
          pages: {
            0: {
              layout: '2x2',
              view: '[]'
            }
          },
          name: 'test1'
        }
      }
    });
    expect(state.scenes).toEqual([
      {
        name: 'test1',
        pages: [
          {
            layout: '2x2',
            view: []
          }
        ]
      }
    ]);
  });

  it.skip('generateDeviceScene should set device scene data with encoders data', () => {
    const encoders = [{ index: 0 }, { index: 3 }];
    const channelCount = 2;
    const state = {
      deviceScene: {},
    };
    const config = {
      camera_ip: '0',
      camera_name: '1',
      camera_time: '1',
      camera_type: '1',
      tmis: '1',
      vca: '1',
    };
    const pipInfo = {
      x: 0,
      y: 0,
      width: 1,
      height: 1
    };
    const dewarpInfo = {
      isValid: true,
      isLocalDewarped: false,
    };

    mutations.generateDeviceScene(state, { layout: '2x2', encoders, channelCount });
    expect(state.deviceScene).toEqual({
      name: 'device-list',
      pages: [{
        layout: '2x2',
        view: [{
          encoder_id: 0, camera_type: 'default', dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 0, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50, stretch: true, timestamp: 0,
        }, {
          encoder_id: 3, camera_type: 'default', dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 0, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50, stretch: true, timestamp: 0,
        }]
      }]
    });
  });

  it('goPreviousPage should change activePageIndex', () => {
    const state = {
      activePageIndex: 1,
    };
    mutations.goPreviousPage(state);
    expect(state.activePageIndex).toEqual(0);
  });

  it('goNextPage should change activePageIndex', () => {
    const state = {
      activePageIndex: 1,
    };
    mutations.goNextPage(state);
    expect(state.activePageIndex).toEqual(2);
  });

  it('goNextNonEmptyPage should skip pages with viewcells all empty', () => {
    const state = {
      isSceneMode: true,
      activePageIndex: 0,
      active: 0,
      scenes: [
        {
          pages: [{
            view: [{ channel: 0, isEmpty: false }]
          }, {
            view: [{ channel: -1, isEmpty: true }]
          }, {
            view: [{ channel: 11, isEmpty: false }]
          }],
        },
      ],
    };
    mutations.goNextNonEmptyPage(state);
    expect(state.activePageIndex).toEqual(2);
  });

  describe.skip('changeLayout', () => {
    it('should cut redundant view items if change from layout w/ more items into less items', () => {
      const state = {
        isSceneMode: true,
        activePageIndex: 1,
        active: 0,
        scenes: [
          {
            pages: [{}, {
              layout: '2x2',
              view: new Array(1).fill({})
            }, {}, {}],
          },
        ],
      };
      mutations.changeLayout(state, '1x1');
      expect(state.scenes[0].pages[1].view.length).toEqual(1);
    });

    it('should add extra view items if change from layout w/ less items into more items', () => {
      const state = {
        isSceneMode: true,
        activePageIndex: 1,
        active: 0,
        scenes: [
          {
            pages: [{}, {
              layout: '1x1',
              view: new Array(1).fill({})
            }, {}, {}],
          },
        ],
      };
      mutations.changeLayout(state, '2x2');
      expect(state.scenes[0].pages[1].view.length).toEqual(4);
    });

    it('should read config object', () => {
      const state = {
        isSceneMode: true,
        scenes: [
          {
            pages: [{}, {
              layout: '1x1',
              view: new Array(1).fill({})
            }, {}, {}],
          },
        ],
      };
      mutations.changeLayout(state, {
        sceneIndex: 0,
        pageIndex: 1,
        layout: '2x2'
      });
      expect(state.scenes[0].pages[1].view.length).toEqual(4);
    });
  });

  it.skip('changeLayout should change layout of active scene\'s active page', () => {
    const state = {
      isSceneMode: true,
      activePageIndex: 1,
      active: 0,
      scenes: [
        {
          pages: [{}, {
            view: [],
          }],
        },
      ],
    };
    mutations.changeLayout(state, '3x3');
    expect(state.scenes[0].pages[1].layout).toEqual('3x3');
    expect(state.activePageIndex).toEqual(0);
  });

  it.skip('changeLayout should change layout of device scene\'s each page when isSceneMode is false', () => {
    const config = {
      camera_ip: '0',
      camera_name: '1',
      camera_time: '1',
      camera_type: '1',
      tmis: '1',
      vca: '1',
    };
    const pipInfo = {
      x: 0,
      y: 0,
      width: 1,
      height: 1
    };
    const dewarpInfo = {
      isValid: true,
      isLocalDewarped: false,
    };
    const state = {
      isSceneMode: false,
      deviceScene: {
        pages: [{
          layout: '2x2',
          view: [{
            encoder_id: 0, dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 0, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50
          }, {
            encoder_id: -1, dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 0, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50
          }]
        }],
      },
    };
    mutations.changeLayout(state, '1x1');
    expect(state.deviceScene.pages[0].layout).toEqual('1x1');
    expect(state.deviceScene.pages).toEqual([{
      layout: '1x1',
      view: [{
        encoder_id: 0, camera_type: 'default', dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 0, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50, stretch: true, timestamp: 0,
      }],
    }, {
      layout: '1x1',
      view: [{
        encoder_id: -1, camera_type: 'default', dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 0, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50, stretch: true, timestamp: 0,
      }],
    }]);
  });

  it.skip('changeLayout should reset device scene\'s each page when isSceneMode is false', () => {
    const config = {
      camera_ip: '0',
      camera_name: '1',
      camera_time: '1',
      camera_type: '1',
      tmis: '1',
      vca: '1',
    };
    const pipInfo = {
      x: 0,
      y: 0,
      width: 1,
      height: 1
    };
    const dewarpInfo = {
      isValid: true,
      isLocalDewarped: false,
    };
    const state = {
      isSceneMode: false,
      deviceScene: {
        pages: [{
          layout: '2x2',
          view: [{
            encoder_id: 0, dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 2, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50
          }, {
            encoder_id: -1, dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 2, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50
          }]
        }],
      },
    };
    mutations.changeLayout(state, '1x1');
    expect(state.deviceScene.pages[0].layout).toEqual('1x1');
    expect(state.deviceScene.pages).toEqual([{
      layout: '1x1',
      view: [{
        encoder_id: 0, camera_type: 'default', dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 0, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50, stretch: true, timestamp: 0,
      }],
    }, {
      layout: '1x1',
      view: [{
        encoder_id: -1, camera_type: 'default', dewarp_mode: 0, dewarp_preset: '', dewarp_info: dewarpInfo, stream_index: 0, display: config, zoom_enable: false, pip_info: pipInfo, volume: 50, stretch: true, timestamp: 0,
      }],
    }]);
  });

  it('createPage should create page of active scene', () => {
    const state = {
      activePageIndex: 1,
      active: 0,
      scenes: [
        {
          pages: [{}, {}],
        },
      ],
    };
    mutations.createPage(state, 0);
    expect(state.scenes[0].pages[2].layout).toEqual('2x2');
  });

  it('createPage should remove page of active scene', () => {
    const state = {
      activePageIndex: 1,
      active: 0,
      scenes: [
        {
          pages: [{}, {}],
        },
      ],
    };
    mutations.removePage(state, { sceneIndex: 0, pageIndex: 1 });
    expect(state.scenes[0].pages.length).toEqual(1);
  });

  it('setViewData should set encoder_id correctly', () => {
    const state = {
      activePageIndex: 1,
      active: 0,
      scenes: [
        {
          pages: [{}, {
            view: [{}, {}, {}, {}],
          }],
        },
      ],
    };
    mutations.setViewData(state, {
      sceneIndex: 0,
      pageIndex: 1,
      viewIndex: 3,
      encoderIndex: 5,
    });
    expect(state.scenes[0].pages[1].view[3].encoder_id).toEqual(5);
  });

  it.skip('setAllViewDisplayConfig should update all viewcell with same display config in deviceScene', () => {
    const config = {
      camera_ip: '0',
      camera_name: '1',
      camera_time: '1',
      camera_type: '1',
      tmis: '1',
      vca: '1',
    };
    const dewarpInfo = {
      isValid: true,
      isLocalDewarped: false,
    };

    const state = {
      isSceneMode: false,
      activePageIndex: 0,
      active: 0,
      deviceScene: {
        name: 'device-list',
        pages: [{
          layout: '2x2',
          view: [{
            encoder_id: 0, dewarp_mode: 0, dewarp_preset: '', stream_index: 2, dewarp_info: dewarpInfo, display: config
          }, {
            encoder_id: -1, dewarp_mode: 0, dewarp_preset: '', stream_index: 2, dewarp_info: dewarpInfo, display: config
          }]
        }],
      },
    };
    mutations.setAllViewDisplayConfig(state, { camera_type: '0' });
    expect(state.deviceScene.pages[0].view[0].display.camera_type).toEqual('0');
    expect(state.deviceScene.pages[0].view[1].display.camera_type).toEqual('0');
  });
});
